import React from 'react';
import advertisingImage from "../../Images/Home/AdvertisingImage.JPG"

function HeroSection() {
    return (
        <header className='hero'>
        <div className='hero-content'>
                <div className='small-hero-spacer' />
                <h1 className='hero-title-text'>The Complete Bike Solution</h1>
                <div className='small-hero-spacer' />
                <div className='small-hero-spacer' />
                <div className='hero-small-header'>Rentals • Delivery • Advertising • Fleet Management</div>
        </div>
        <div className='hero-image-wrapper'>
                <img src={advertisingImage} className='hero-image' alt='serviceimage'/>
            </div>
        </header>
        );
  }

  export default HeroSection