import React from 'react';

function ServiceInfoCell({ id, title, description, image, imageLeft = true }) {

    return (
    <div id={id} className='service-info-cell'>
        { imageLeft && ( <div className='service-info-image-wrapper'>
            <img src={image} className='service-info-image' alt='serviceimage'/>
        </div>  )}
        <div className='service-info-text-block'>
            <h1>{title}</h1>
            <p>{description}</p>
        </div>
        { !imageLeft && ( <div className='service-info-image-wrapper'>
            <img src={image} className='service-info-image' alt='serviceimage'/>
        </div>  )}
    </div>
    );
  }

export default ServiceInfoCell